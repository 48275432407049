import React, { memo } from "react"
import { Checkbox, FormControl } from "@chakra-ui/react"

type Props = {
  field: {
    _key: string
    _type: string
    label: string
    name: string
    placeholder?: string
    type: "text" | "email" | "tel"
    required?: boolean
  }
}

export const FormCheckbox: React.FC<Props> = memo(({ field }) => (
  <FormControl key={field._key} id={field?.name} isRequired={field?.required} variant="default">
    <Checkbox value={field?.name}>{field?.label}</Checkbox>
  </FormControl>
))
